<template>
<div class="talentRecruitment">
  <CompanyProfileBanner :index="'4'" :imageUrl="imageUrl"></CompanyProfileBanner>
  <BreadcrumbAndSubtag></BreadcrumbAndSubtag>
  <div class="recruitContent">
    <div class="contents">
      <div class="talentConcept">
        <div class="title">
          <h1>TALENT CONCEPT</h1>
          <h2>人才理念</h2>
        </div>
        <ul>
          <li>
            <img src="@/assets/image/joinUs1.png" />
            <h2>人适其岗</h2>
          </li>
          <li>
            <img src="@/assets/image/joinUs2.png" />
            <h2>岗适其人</h2>
          </li>
          <li>
            <img src="@/assets/image/joinUs3.png" />
            <h2>人尽其才</h2>
          </li>
          <li>
            <img src="@/assets/image/joinUs4.png" />
            <h2>才尽其用</h2>
          </li>
        </ul>
      </div>
      <div class="positions">
        <div class="title">
          <h1>RECRUITMENT POSITION</h1>
          <h2>招聘岗位</h2>
        </div>
        <div class="contact">
          <p>联系人：<b>{{ contacts }}</b></p>
          <p>联系方式：<b>{{ contactUs[0] }}</b></p>
        </div>
        <el-collapse v-if="jobList.length>0" v-model="activeNames" accordion>
          <el-collapse-item
            v-for="item in jobList"
            :key="item.id"
            :name="item.name"
          >
            <template slot="title">
              <b style="padding: 0 20px;width: 130px;text-align: left">{{ item.name }}</b>
              <el-tag size="small" effect="dark" style="width: 50px">{{ item.num }}人</el-tag>
              <span style="padding-left: 20px">截止时间：{{ item.deadline }}</span>
            </template>
            <p class="titleLabel">职位描述：</p>
            <div class="describe">
              <p v-for="des in item.description.split('\n')"
                 :key="des">
                {{des}}
              </p>
            </div>
            <p class="titleLabel">职位要求：</p>
            <div class="describe">
              <p v-for="des in item.requirement.split('\n')"
                 :key="des">
                {{des}}
              </p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="promotion">
        <div class="title">
          <h1>PROMOTION PATH</h1>
          <h2>晋升途径</h2>
        </div>
        <div class="narrate">
          <p>
            员工入职后开展新员工培训，主要包括公司发展历程、公司制度、企业文化、工作职责、职业规划等。
          </p>
          <p>
            每个部门指定入职导师，带领其熟悉部门环境，对工作进行指导，缩短熟悉周期，让新员工尽快融入团队。
          </p>
          <p>
            在公司规模扩大的今天，员工的满足感将不仅仅来自传统的晋升，而且还来自专业技术水平的提高、管理技能的提高以及获得的尊重认可等多个方面
          </p>
          <p>
            <b>纵向发展：</b>
            在公司关键岗位出现职位空缺时，内部员工将优先获得晋升和发展机会，公司执行竞争上岗、择优录用的制度。
          </p>
          <p>
            <b>横向发展：</b>
            为满足员工发展需求，公司鼓励员工跨岗位轮岗。
          </p>
        </div>
      </div>
    </div>
  </div>
  <footerMain></footerMain>
</div>
</template>

<script>
import CompanyProfileBanner from '@/components/companyProfileBanner'
import BreadcrumbAndSubtag from '@/components/breadcrumbAndSubtag'
import footerMain from '@/components/footerMain'

export default {
  name: "talentRecruitment",
  components: {
    CompanyProfileBanner,
    BreadcrumbAndSubtag,
    footerMain
  },
  data() {
    return {
      imageUrl: '',
      contacts: contacts,
      contactUs: contactUs,
      activeNames: [],
      jobList: []
    }
  },
  created() {
    this.getJobListFun();
    this.loadResourceOfPageFun()
  },
  methods: {
    changeActiveTabFun(val) {
      this.activeNowPath = val
      this.getArticleListFun()
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', val)
    },
    loadResourceOfPageFun() {
      api.getResourceOfPage({pageId: 14, locationType: 1}).then((res) => {
        if(res.code === 100) {
          this.imageUrl = resAddressPrefix + res.data[0].images[0].imageUrl
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    getJobListFun() {
      api.getJobList().then((res) => {
        if(res.code === 100) {
          this.jobList = []
          this.activeNames = []
          this.jobList = res.data;
          this.activeNames = res.data[0].name
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.talentRecruitment{
  .recruitContent {
    margin: auto;
    //display: table;
    //vertical-align: middle;
    .contents {
      width: 1200px;
      padding: 4em 0;
      margin: auto;
      //display: table-cell;
      //vertical-align: middle;
      .talentConcept {
        ul {
          list-style: none;
          margin: 20px auto 60px auto;
          padding: 0;
          height: 180px;
          display: -webkit-flex; /* Safari */
          -webkit-justify-content: space-around; /* Safari 6.1+ */
          display: flex;
          justify-content: space-around;
          li {
            float: left;
            text-align: center;
            h2 {
              margin-top: 10px;
              font-size: 22px;
            }
          }
        }
      }
      .positions {
        .contact {
          text-align: right;
          p {
            margin: 0;
            font-size: 14px;
          }
        }
        ::v-deep .el-collapse {
          margin-bottom: 4em;
          border-radius: 4px;
          border: 1px solid #B8B8B8;
          .el-collapse-item__header,
          .el-collapse-item__wrap{
            border-bottom: 1px solid #B8B8B8;
          }
          .el-collapse-item__content {
            text-align: left;
            padding: 20px;
            .titleLabel {
              font-size: 14px;
              margin: 20px 0 6px 0;
            }
            .describe {
              p {
                margin-top: 0;
                margin-bottom: 4px;
                line-height: 1.6;
              }
            }
          }

        }
      }
      .promotion {
        .narrate {
          p {
            color: #555;
            line-height: 1.8;
            text-indent: 2em;
            font-size: 16px;
            text-align: left;
          }
        }
      }

      .title {
        padding-bottom: 4em;
        h1 {
          margin: 0;
          color: #F6F6F6;
          font-size: 54px;
        }
        h2 {
          margin: -54px 0 0 0;
          font-size: 28px;
        }
      }

    }
  }
}
</style>
